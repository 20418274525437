import { createRouter, createWebHashHistory } from 'vue-router'
const routerHistory = createWebHashHistory()

const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/',
            name: "Home",
            component: () => import("../pages/Home/Home.vue"),
            children: [
                {
                    path: '/',
                    name: 'content',
                    component: () => import("../pages/Content/Content.vue"),
                    meta: { title: "系统首页" }
                },
                {
                    path: '/menu',
                    name: 'menu',
                    component: () => import("../pages/Managemenu/Manage_menu.vue"),
                    meta: { title: "管理菜单" }
                },
                {
                    path: '/feedback',
                    name: 'feedback',
                    component: () => import("../pages/Feedback/index.vue"),
                    meta: { title: "问题反馈" }
                },
            ]
        },
        {
            path: '/login',
            name: "Login",
            component: () => import("../pages/Login/login.vue"),
            meta: { title: "用户登录" }

        },

    ]
})

export default router
