import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router/index'
import './assets/iconfont/iconfont.css'
import store from './store/index'

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : 'FAQ'
    next()
})
const app = createApp(App)
app.use(store).use(router).use(ElementPlus).mount('#app')
