import { createStore } from 'vuex'

export default createStore({
    state: {
        isShowEditor: false,//显示富文本编辑
        articleTitleList: null,//右边问题内容显示
        menuArr: [],//菜单List
        menuId: null, //菜单id
        modifyId: null,//修改文章的id
        menuFatherId: null,
        modifyContent: null,
        isModifyStatus: false,
        contentLoading: false,
        menuName: null,
        defaultIndexMenu:"hot",

    },
    mutations: {
        //存文章title
        setArticleTitleList(state, data) {
            state.articleTitleList = data
        },
        //新增修改时 显示富文本
        setIsShowEditor(state, data) {
            state.isShowEditor = data
        },
        //保存菜单大类
        setMenu(state, data) {
            state.menuArr = data
        },
        //菜单id
        setId(state, data) {
            state.menuId = data
        },
        setDefaultIndexMenu(state,data){
            state.defaultIndexMenu = data
        },
        //新增或者是修改文章id
        setModifyId(state, data) {
            state.modifyId = data
        },
        setMenuFatherId(state, data) {
            state.menuFatherId = data
        },
        setModifyContent(state, data) {
            state.modifyContent = data
        },
        setIsModifyStatus(state, data) {
            state.isModifyStatus = data
        },
        setContentLoading(state, data) {
            state.contentLoading = data
        },
        setMenuName(state, data) {
            state.menuName = data
        }
    },
    getters: {

    },
    actions: {},
    modules: {}
});
